<template>
	<q-card style="width: 700px; max-width: 80vw;">
		<q-card-section>
			<div class="text-h6">Edit media file</div>
		</q-card-section>
		<q-card-section class="q-py-none">
			<q-select
				label="Dataset"
				v-model="customer_group"
				:options="customer_groups"
				:option-label="(item) => item.name"
				name="customer_group"
				@input="onCustomerGroupChange(customer_group.id)"
				:error="has_customer_group_error"
				:error-message="customer_group_error_message"
			/>
			<q-select
				class="q-mb-md"
				v-model="media_file_file_privacy"
				:options="file_privacy_options"
				label="File privacy *"
				name="file_privacy"
				:error="has_file_privacy_error"
				:error-message="file_privacy_error_message"
				@input="onFilePrivacyChange(media_file_file_privacy)"
			/>
			<q-input
				outlined
				label="File name *"
				v-model="media_file_name"
				:error="has_file_name_error"
				:error-message="file_name_error_message"
			/>
			<q-input
				outlined
				class="q-mb-md"
				v-model="media_file_order_number"
				type="number"
				label="Display order"
				name="display_order"
				:error="has_order_number_error"
				:error-message="order_number_error_message"
				onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
			/>
			<span>
				{{media_file_name}}
			</span>
			<q-file
				v-model="media_file_attachment"
				outlined
				class="q-mb-md"
				color="primary"
				label="Choose new file"
				:name="media_file_name"
				@input="uploadAttachment(media_file_attachment)"
			>
				<template v-slot:prepend>
					<q-icon name="attach_file" />
				</template>
			</q-file>
			<q-input
				class="q-mb-md"
				outlined
				v-model="expiry_date"
				name="expiry_date"
				label="Expiry date *"
				hint="Format DD/MM/YYYY"
				@click="expiry_date_dialog=true"
				:error="has_expiry_date_error"
				:error-message="expiry_date_error_message"
			>
				<template v-slot:append>
					<q-btn flat icon="event" class="cursor-pointer date-btn" @click="expiry_date_dialog=true">
						<q-dialog v-model="expiry_date_dialog">
							<q-date
								v-model="expiry_date"
								mask="DD/MM/YYYY"
								color="primary"
								:options="limitExpiryDate"
							>
								<div class="row items-center justify-end">
									<q-btn v-close-popup label="Close" color="primary" flat />
								</div>
							</q-date>
						</q-dialog>
					</q-btn>
				</template>
			</q-input>
			<q-input
				outlined
				@click="review_date_dialog=true"
				v-model="review_date"
				name="review_date"
				label="Review date *"
				hint="Format DD/MM/YYYY"
				:error="has_review_date_error"
				:error-message="review_date_error_message"
				:disable="expiry_date ? false : true"
			>
				<template v-slot:append>
					<q-btn flat icon="event" class="cursor-pointer date-btn" @click="review_date_dialog=true">
						<q-dialog v-model="review_date_dialog">
							<q-date
								v-model="review_date"
								mask="DD/MM/YYYY"
								color="primary"
								:options="limitReviewDate"
							>
								<div class="row items-center justify-end">
									<q-btn v-close-popup label="Close" color="primary" flat />
								</div>
							</q-date>
						</q-dialog>
					</q-btn>
				</template>
			</q-input>
		</q-card-section>
		<q-card-actions class="justify-end">
			<q-btn flat label="Cancel" color="primary" @click="$emit('close')" />
			<q-btn flat label="Save" color="primary" @click="saveMediaFile()" />
		</q-card-actions>
	</q-card>
</template>

<script>
import { date } from 'quasar'
import { mapActions, mapGetters } from 'vuex';
import MediaLibraryAPI from '@/services/api/MediaLibrary.js';

export default {
	name: "EditFileModal",
	props:{
		media_file: Object,
	},
	data(){
		return{
			media_file_name: "",
			media_file_order_number: "",
			customer_groups: [],
			file_privacy_options: [],
			media_file_attachment: undefined,
			media_file_customer_group: "",
			customer_group: {},
			media_file_file_privacy: "",
			old_media_file_name: "",
			expiry_date: "",
			review_date: "",
			last_dot_index: null,
			media_file_type: "",
			has_customer_group_error: false,
			has_file_privacy_error: false,
			has_file_name_error: false,
			has_media_file_attachment_error: false,
			has_expiry_date_error: false,
			has_review_date_error: false,
			has_order_number_error: false,
			customer_group_error_message: '',
			file_privacy_error_message: '',
			media_file_attachment_error_message: '',
			expiry_date_error_message: '',
			review_date_error_message: '',
			file_name_error_message: '',
			order_number_error_message: '',
			date_today: '',
			expiry_date_dialog: false,
			review_date_dialog: false,
		}
	},

	computed:{
		...mapGetters(['current_CG', 'order_by', 'is_descending', 'timeout_message_media_library', 'successfull_message']),
		date_time_format: function () {
			return {
				weekday: "short",
				month: "short",
				day: "2-digit",
				year: "numeric",
			};
		},
	},
	methods: {
		...mapActions(["editFileInMediaLibrary", "loadInitialData"]),
		checkErrors(){
			let has_error = false
			if (!this.customer_group){
				this.has_customer_group_error = true
				this.customer_group_error_message = 'Please select customer dataset'
				has_error = true
			}
			if (!this.media_file_file_privacy){
				this.has_file_privacy_error = true
				this.file_privacy_error_message = 'Please set up privacy options for the file'
				has_error = true
			}
			if (!this.media_file_name){
				this.has_file_name_error = true
				this.file_name_error_message = 'Please enter file name.'
				has_error = true
			}
			if (this.media_file_name && this.media_file_name.length > 100){
				this.has_file_name_error = true
				this.file_name_error_message = 'The name of the file should have no more than 100 characters.'
				has_error = true
			}
			if (!this.expiry_date){
				this.has_expiry_date_error = true
				this.expiry_date_error_message = 'Please set an expiry date for your file'
				has_error = true
			}
			if (!this.review_date){
				this.has_review_date_error = true
				this.review_date_error_message = 'Please set up review date'
				has_error = true
			}
			if (this.expiry_date && this.review_date){
				let expiry_date_formated =
					`${this.expiry_date.split('/')[2]}-${this.expiry_date.split('/')[1]}-${this.expiry_date.split('/')[0]}`
				let review_date_formated = 
					`${this.review_date.split('/')[2]}-${this.review_date.split('/')[1]}-${this.review_date.split('/')[0]}`
				if (expiry_date_formated < review_date_formated){
					this.has_review_date_error = true
					this.review_date_error_message = 'The review date must be lesser or equal to the expiry date'
					has_error = true
				}
			}
			if (this.media_file_order_number && this.media_file_order_number < 0){
				this.has_order_number_error = true
				this.order_number_error_message = 'The order number must be a positive number'
				has_error = true
			}
			return has_error
		},
		resetErrorValues(){
			this.has_customer_group_error =  false
			this.has_file_privacy_error =  false
			this.has_media_file_attachment_error =  false
			this.has_expiry_date_error =  false
			this.has_review_date_error = false
			this.has_file_name_error = false
			this.has_order_number_error = false
			this.customer_group_error_message =  ''
			this.file_privacy_error_message =  ''
			this.media_file_attachment_error_message =  ''
			this.expiry_date_error_message =  ''
			this.review_date_error_message = ''
			this.file_name_error_message = ''
			this.order_number_error_message = ''
		},
		async saveMediaFile(){
			this.$store.commit('setMediaLibraryTimeoutMessage', '');
			let form_data = new FormData();
			this.resetErrorValues()
			if(this.media_file_attachment){
				form_data.append('file', this.media_file_attachment);
				form_data.append('is_new_file', true);
			}
			if (this.checkErrors()){
				return
			}else{
				this.$q.loading.show({
					delay: 400,
				})
				let format_expiry_date =
					`${this.expiry_date.split('/')[2]}-${this.expiry_date.split('/')[1]}-${this.expiry_date.split('/')[0]}`
				let formated_review_date =
					`${this.review_date.split('/')[2]}-${this.review_date.split('/')[1]}-${this.review_date.split('/')[0]}`

				const expiry_date = this.getDate(format_expiry_date);
				const review_date = this.getDate(formated_review_date);
				form_data.append('customer_group', this.media_file_customer_group);
				form_data.append('file_privacy', this.media_file_file_privacy);
				form_data.append('file_name', this.media_file_name);
				form_data.append('order_number', this.media_file_order_number ? this.media_file_order_number : "");
				form_data.append('expiry_date', expiry_date ? expiry_date : "");
				form_data.append('review_date', review_date ? review_date : "");
				form_data.append('file_type', this.media_file_type)
				await this.editFileInMediaLibrary({"data": form_data, "media_file_id": this.media_file.id, "order_by": this.order_by, "is_descending": this.is_descending});
				if(!this.timeout_message_media_library){
					await this.loadInitialData();
					this.$q.notify(this.successfull_message)
					this.$emit('close')
				}else{
					this.showErrorNotification(this.timeout_message_media_library)
				}
				this.$q.loading.hide()
			}
		},
		showErrorNotification(msg) {
			this.$q.notify({
				timeout: 6700,
				message: msg,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		},
		getDate(date){
			return date.split("T")[0]
		},
		uploadAttachment(media_file_attachment){
			this.has_media_file_attachment_error = false
			this.media_file_attachment_error_message = ''
			this.is_file_attached = true;
			this.last_dot_index = media_file_attachment.name.lastIndexOf('.');
			if (this.last_dot_index != -1){
				this.media_file_type = media_file_attachment.name.substr(this.last_dot_index + 1);

			}
			if(media_file_attachment.size >= 104857600){
				this.has_media_file_attachment_error = true
				this.media_file_attachment_error_message = 'The file exceeds the maximum upload size. Please upload a file smaller than 100 MB for video 50MB for other file types.'
			}else{
				this.media_file_attachment = media_file_attachment;
				this.media_file_name = media_file_attachment.name;
			}
		},
		onCustomerGroupChange(id){
			this.media_file_customer_group = id;
		},
		onFilePrivacyChange(file_privacy){
			this.media_file_file_privacy = file_privacy;
		},
		cancelAttachment(){
			this.media_file_attachment = null;
			this.media_file_name = this.old_media_file_name
		},
		limitExpiryDate(date){
			return date >= this.date_today
		},
		limitReviewDate(rev_date){
			let ex_date = `${this.expiry_date.split('/')[2]}/${this.expiry_date.split('/')[1]}/${this.expiry_date.split('/')[0]}`
			return rev_date <= ex_date && rev_date >= this.date_today
		},
		formatDateStr(date){
			return `${date.split('-')[2]}/${date.split('-')[1]}/${date.split('-')[0]}`
		}
	},
	async created(){
		await this.loadInitialData();
		this.$store.commit('setMediaLibraryTimeoutMessage', '');
		this.customer_groups = await MediaLibraryAPI.getCustomerGroups();
		for(let customer_group of this.customer_groups){
			if(customer_group.id == this.media_file.customer_group){
				this.customer_group = JSON.parse(JSON.stringify(customer_group));
			}
		}
		let root_cg_permissions = this.current_CG.root_customer_group_permissions;
		if (root_cg_permissions.indexOf('Public links for media library files') >= 0){
			this.file_privacy_options = ["private" ,"public", "shared"]
		}else{
			this.file_privacy_options = ["private", "shared"]
		}
		this.media_file_name = this.media_file.name;
		this.media_file_order_number = this.media_file.order_number;
		this.media_file_file_privacy = this.media_file.file_privacy;
		this.media_file_customer_group = this.media_file.customer_group;
		this.old_media_file_name = this.media_file_name;
		let new_date = new Date();
		new_date.setDate(new_date.getDate() + 1);
		this.date_today = date.formatDate(new_date, 'YYYY/MM/DD')
		if (this.media_file.expiry_date){
			this.expiry_date = this.formatDateStr(this.media_file.expiry_date)
		}
		if (this.media_file.review_date){
			this.review_date = this.formatDateStr(this.media_file.review_date)
		}
	},
}
</script>
<style lang="scss">
	.date-btn .q-btn__wrapper {
		padding: 0.5rem;
	}
</style>
