<template>
	<div id="top">
		<q-btn
			v-if="media_file.file_privacy === 'public' && public_link_feature && !is_public_file"
			flat
			round
			color="dark"
			icon="o_link"
			@click="copyText()"
		>
			<q-tooltip v-if="!permanent_link" anchor="top middle" :delay="500">
				Copy file link
			</q-tooltip>
			<q-tooltip v-else anchor="top middle" :delay="500">
				{{permanent_link}}
			</q-tooltip>
		</q-btn>
		<q-btn
			v-if="['ADMIN', 'OPERATOR'].includes(logged_user.customer_permission)"
			flat
			round
			color="dark"
			icon="o_file_download"
			@click="downloadFile(media_file)"
		>
			<q-tooltip anchor="top middle" :delay="500">
				Download file
			</q-tooltip>
		</q-btn>
		<q-btn
			v-if="['ADMIN', 'OPERATOR'].includes(logged_user.customer_permission) && !is_public_file"
			flat
			round
			color="dark"
			icon="o_edit"
			@click="show_edit_file_modal = true"
		>
			<q-tooltip anchor="top middle" :delay="500">
				Edit file
			</q-tooltip>
		</q-btn>
		<q-dialog v-model="show_edit_file_modal">
			<EditFileModal
				:media_file="media_file"
				@close="show_edit_file_modal=false"
			/>
		</q-dialog>
		<q-btn
			v-if="['ADMIN', 'OPERATOR'].includes(logged_user.customer_permission) && !is_public_file"
			flat
			round
			color="dark"
			icon="o_delete"
			@click="show_delete_file_modal = true"
		>
			<q-tooltip anchor="top middle" :delay="500">
				Delete file
			</q-tooltip>
		</q-btn>
		<q-dialog v-model="show_delete_file_modal">
			<DeleteFileModal
				:media_file="media_file"
				@close="show_delete_file_modal=false"
			/>
		</q-dialog>
	</div>
</template>

<script>
import MediaLibraryAPI from '@/services/api/MediaLibrary.js';
import DeleteFileModal from '@/components/media_library/DeleteFileModal.vue';
import EditFileModal from '@/components/media_library/EditFileModal.vue';
import moment from 'moment-timezone'
import { mapGetters } from 'vuex';
import { copyToClipboard } from 'quasar'
import utils from '@/services/utils.js'

export default {
	name: "MediaLibraryFile",
	props: {
		media_file: Object,
		is_public_file: Boolean,
	},
	components: {
		DeleteFileModal,
		EditFileModal,
	},
	data(){
		return{
			show_delete_file_modal: false,
			show_edit_file_modal: false,
			last_updated_date: moment.tz(this.media_file.last_updated_at, 'UTC'),
			preferred_timezone: "",
			file_extension: "",
			public_link_feature: false,
			permanent_link: "",
		}
	},
	computed: {
		...mapGetters(['logged_user', 'current_CG',]),
	},
	methods: {
		async downloadFile(media_file) {
			let response = await MediaLibraryAPI.getFile(media_file['id']);
			let file_extension = media_file.name.split('.').pop();
			if(response.error){
				this.scrollToTop();
				this.$store.commit('setIsErrorMessage', true);
				this.$store.commit('setSuccessfullMessage', response.error_message);
				return;
			}
				utils.directDownloadFile(media_file.name, file_extension, response)
		},
		scrollToTop(){
			var element = document.getElementById("top");
			element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
		},
		async copyText(){
			this.permanent_link = await MediaLibraryAPI.getFile(this.media_file.id);
			if(this.permanent_link.error_message){
				this.showErrorNotification(this.permanent_link.error_message)
				return
			}
			copyToClipboard(this.permanent_link).then(() => {
				this.$q.notify('Link copied successfully.')
			}).catch(() => {
				this.showErrorNotification('There was an error. Please try again.')
			})
		},
		showErrorNotification(msg){
			this.$q.notify({
				timeout: 6700,
				message: msg,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		}
	},
	created(){
		if (this.logged_user) {
			this.preferred_timezone = this.logged_user.preferred_timezone;
		}
		const root_cg_permissions = this.current_CG.root_customer_group_permissions;
		this.public_link_feature = (
			root_cg_permissions.indexOf('Public links for media library files') >= 0 &&
			this.logged_user.customer_permission === 'ADMIN'
		)
	},
	watch: {
		media_file: {
			deep: true,
			handler() {
				this.last_updated_date = moment.tz(this.media_file.last_updated_at, 'UTC');
			}
		},
	}
}
</script>
