<template>
	<q-card>
		<q-card-section>
			<span>
				This will also delete this file from your saved message templates. Are you sure you want to delete?
			</span>
		</q-card-section>
		<q-card-actions class="justify-end">
			<q-btn flat label="Cancel" color="primary" @click="$emit('close')" />
			<q-btn flat label="Delete" color="primary" @click="deleteFile()" />
		</q-card-actions>
	</q-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	name: "DeleteFileModal",
	props:{
		media_file: Object,
	},
	methods: {
		...mapActions(["deleteMediaLibraryFile"]),
		async deleteFile(){
			let response = await this.deleteMediaLibraryFile(
				{
					"media_file": this.media_file.id,
				}
			);
			if(!response){
				this.$q.notify('The file was successfully deleted.')
				this.$emit('close')
			}else{
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				this.$emit('close')
			}
		},
	},
}
</script>
