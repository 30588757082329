<template>
	<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Media library
		</h1>
		<div v-if="!is_user_contact">
			<CustomerGroup
				:is_message_forms="is_message_forms"
			/>
		</div>
		<div class="list" v-if="!is_message_forms">
			<div>
				<PublicFiles
					:public_files="public_files"
				/>
			</div>
		</div>
		<span v-if="['ADMIN', 'OPERATOR'].includes(logged_user.customer_permission) && !is_message_forms">
			<q-fab
				class="flex justify-end q-mt-md"
				style="position: sticky; bottom: 50px; z-index: 1;"
				:label="$q.screen.gt.sm ? 'Actions' : ''"
				label-position="left"
				color="primary"
				icon="keyboard_arrow_up"
				direction="up"
			>

				<q-fab-action
					color="primary"
					icon="add"
					:label="$q.screen.gt.sm ? 'Upload file' : ''"
					@click="addNewFile()"
				>
					<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
						Upload file
					</q-tooltip>
				</q-fab-action>
				<q-fab-action
					v-if="logged_user.customer_permission === 'ADMIN' && should_display"
					color="primary"
					icon="download"
					:label="$q.screen.gt.sm ? 'Download all files' : ''"
					@click="downloadAll()"
				>
					<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
						Download all files
					</q-tooltip>
				</q-fab-action>
				<q-fab-action
					v-if="logged_user.customer_permission === 'ADMIN' && should_display"
					color="primary"
					icon="delete"
					:label="$q.screen.gt.sm ? 'Delete all files' : ''"
					@click="delete_dialog=true"
				>
					<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
						Delete all files
					</q-tooltip>
				</q-fab-action>
			</q-fab>
		</span>
		<q-dialog v-model="show_add_new_file">
			<AddFileModal
				:customer_groups="customer_groups"
				@close="closeModal"
			/>
		</q-dialog>
		<q-dialog v-model="delete_dialog">
			<q-card>
				<q-card-section class="text-center">
					<span>Are you sure you want to delete the all the files?</span>
				</q-card-section>
				<q-card-actions align="right">
					<q-btn flat label="Cancel" color="primary" v-close-popup />
					<q-btn
						flat
						label="Delete"
						color="primary"
						@click="deleteAll"
					/>
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<script>
	import CustomerGroup from "../components/media_library/CustomerGroup.vue";
	import AddFileModal from "@/components/media_library/AddFileModal.vue";
	import PublicFiles from '@/components/media_library/PublicFiles.vue';
	import MediaLibraryAPI from '@/services/api/MediaLibrary.js';
	import { mapGetters, mapActions } from "vuex";

	export default {
		name: "MediaLibrary",
		meta: {
			title: 'Alert Cascade - Media Library'
		},
		components: {
			AddFileModal,
			PublicFiles,
			CustomerGroup,
		},
		data(){
			return{
				show_add_new_file: false,
				customer_groups: [],
				is_user_contact: false,
				delete_dialog: false
			}
		},
		props: {
			is_message_forms: Boolean
		},
		computed: {
			...mapGetters([
				'logged_user',
				'public_files',
				'current_CG',
				'selected_cg'
			]),
			should_display() {
				if(this.$store.getters.selected_cg.media_files) {
					return this.$store.getters.selected_cg.media_files.length
				}
				return false
			}
		},
		methods:{
			...mapActions(["loadInitialData"]),
			addNewFile(){
				this.show_add_new_file = true;
			},
			closeModal(){
				this.show_add_new_file = false;
			},
			async downloadAll() {
				try {
					this.$q.loading.show({
						delay: 400,
					})
					const resp = await MediaLibraryAPI.downloadAllFiles(this.selected_cg.customer_group_id)
					if (resp.error) {
						this.$q.notify(resp.error_message)
					} else {
						this.$q.notify(resp.data)
					}
					this.$q.loading.hide()
				} catch(error) {
					this.$q.notify({
						timeout: 0,
						message: 'Download failed.',
						actions: [
							{
								label: 'Dismiss',
								color: 'primary',
							},
						],
					})
				}
			},
			async deleteAll() {
				try {
					this.delete_dialog = false
					this.$q.loading.show({
						delay: 400,
					})
					const resp = await MediaLibraryAPI.deleteAllFiles(this.selected_cg.customer_group_id)
					if (resp.error) {
						this.$q.notify(resp.error_message)
					} else {
						this.$q.notify(resp.data)
					}
					this.$q.loading.hide()
					this.loadInitialData();
				} catch(error) {
					this.$q.notify({
						timeout: 0,
						message: 'Delete failed.',
						actions: [
							{
								label: 'Dismiss',
								color: 'primary',
							},
						],
					})
				}
			}
		},
		async created(){
			await this.loadInitialData();
			this.customer_groups = await MediaLibraryAPI.getCustomerGroups();
			this.is_user_contact = this.logged_user.customer_permission == 'CONTACT' ? true : false
		},
	};
</script>

<style lang="scss">
	.list {
		margin-top: 2rem;
	}
</style>
