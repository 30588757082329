<template>
	<div>
		<q-select
			v-if="media_library_data.length"
			class="q-my-md"
			:options="filter_options"
			:option-label="(item) => item.customer_group_name"
			v-model="selected_cg"
			label="Dataset"
			name="customer_group"
			use-input
			input-debounce="0"
			@filter="filterFn"
		/>
		<div>
			<MediaLibraryTable
				v-if="selected_cg"
				:media_files="selected_cg.media_files"
				:is_message_forms="is_message_forms"
			/>
		</div>
	</div>
</template>

<script>
import MediaLibraryTable from "@/components/media_library/MediaLibraryTable.vue";
import { mapGetters, mapActions } from "vuex";
export default {
	name: 'CustomerGroup',
	components: {
		MediaLibraryTable,
	},
	data(){
		return{
			filter_options: [],
		}
	},
	props: {
		is_message_forms: Boolean
	},
	computed:{
		...mapGetters(['media_library_data',]),
		selected_cg: {
			get(){
				return this.$store.getters.selected_cg;
			},
			set(value){
				if (this.media_library_data.length){
					let index = this.media_library_data.indexOf(value)
					this.$store.dispatch('updateSelectedCgIndex', index);
				}
				this.$store.dispatch('updateSelectedCg', value);
			}
		},
		selected_cg_index: {
			get(){
				return this.$store.getters.selected_cg_index;
			},
			set(val){
				this.$store.dispatch('updateSelectedCgIndex', val);
			}
		},
	},
	methods:{
		...mapActions(["loadInitialData"]),
		filterFn (val, update, type) {
			if (val === '') {
				update(() => {
					this.filter_options = this.media_library_data
				})
				return
			}
			update(() => {
				const needle = val.toLowerCase()
				this.filter_options = this.media_library_data.filter(({customer_group_name}) => customer_group_name.toLowerCase().indexOf(needle) > -1)
			})
		}
	},
	async created(){
		this.$q.loading.show({
			delay: 400,
		})
		await this.loadInitialData();
		this.$q.loading.hide()
		if (this.media_library_data){
			this.selected_cg = this.media_library_data[0]
			this.selected_cg_index = 0
		}
	}
}
</script>
