<template>
	<div>
		<div
			:class="title_el_class_list"
			@click="toggleCollapse"
			@mouseenter="mouseEnter"
			@mouseleave="mouseLeave"
		>
			<div class="row items-center">
				<div class="text-grey-10 q-mr-sm">Shared Files</div>
				<q-btn
					class="expand-btn"
					flat
					icon="expand_less"
					:class="icon_btn_class_list"
				/>
			</div>
		</div>
		<div id="public_files" :class="children_list_class_list">
			<MediaLibraryTable
				:is_public_file="true"
				:media_files="public_files"
				:is_message_forms="false"
			/>
		</div>
	</div>
</template>

<script>
import MediaLibraryFile from '@/components/media_library/MediaLibraryFile.vue';
import MediaLibraryTable from "@/components/media_library/MediaLibraryTable.vue";
export default {
	name: "CustomerGroup",
	components:{
		MediaLibraryFile,
		MediaLibraryTable
	},
	props:{
		public_files: Array,
	},
	computed: {
		title_el_class_list() {
			return {
				'title-element': true,
				'cursor-pointer': this.public_files,
				'cursor-default': !this.public_files,
				'non-selectable': this.public_files,
			}
		},
		icon_btn_class_list() {
			return {
				'rotate-180': !this.expanded,
				'icon-transition': this['icon-transition'],
			}
		},
		children_list_class_list() {
			return {
				'children-list-element': true,
				collapsed: !this.public_files || !this.expanded,
				'opacity-0': !this.expanded,
				'border-left': this.expanded && this.hovered,
				'dataset-transition': this['dataset-transition'],
			}
		},
		true_max_height() {
			let child_count = this.public_files.length
			return this.$el.offsetHeight * child_count
		},
	},
	data(){
		return{
			show_menu: false,
			add_border: false,
			hovered: false,
			expanded: false,
			has_public_files: false,
		}
	},
	methods:{
		toggleCollapse() {
			if (!this.public_files) {
				return
			}
			let media_files_list = document.querySelector('#public_files')
			this.hovered = true
			if (!this.expanded) {
				this.expanded = true
				let media_height = media_files_list.scrollHeight
				media_files_list.style.maxHeight = media_height + 'px'
				setTimeout(() => {
					this.resetMaxHeight()
				}, 500)
			} else {
				this.expanded = false
			}

		},
		mouseEnter() {
			this.hovered = true
		},
		mouseLeave() {
			this.hovered = false
		},
		resetMaxHeight() {
			let media_files_list = document.querySelector('#public_files')
			media_files_list.style.maxHeight = this.true_max_height + 'px'
		},
	},
}
</script>

<style lang="scss">
	.expand-btn .q-btn__wrapper{
		padding: 0px;
	}
</style>
<style lang="scss" scoped>
	.dataset-transition {
		transition-property: opacity, max-height;
		transition-duration: 500ms;
		transition-timing-function: ease-in-out;
	}
	.icon-transition {
		transition: all 500ms ease-in-out;
	}
	.border-left {
		border-left: 1px solid $grey-4;
	}
	.border-bottom {
		border-bottom: 1px solid $grey-8;
	}
	.title-element {
		padding: 0.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-color: $grey-8;
		font-size: 1rem;
		&:hover {
			background-color: $grey-2;
		}
	}
	.children-list-element {
		overflow: hidden;
		border-color: $grey-8;
	}
	.collapsed {
		max-height: 0px !important;
	}
</style>